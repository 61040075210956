/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'
import { Box, Text } from 'rebass/styled-components'
import { ArrowIcon } from './icons/arrow'

export const Headline = forwardRef((props, ref) => {
    return (
        <Text
            ref={ref}
            letterSpacing="1px"
            fontWeight="regular"
            fontFamily="book"
            lineHeight={1.4}
            fontSize={props.fontSize || [3, 3, 4, 5]}
            {...props}>
            {props.children}
        </Text>
    )
})

export const Body = (props) => {
    return (
        <Text
            letterSpacing="1px"
            lineHeight={1.25}
            fontWeight="regular"
            fontFamily="medium"
            fontSize={[1, 1, 2, 2]}
            color="gray"
            sx={{ a: { color: 'teal', textDecoration: 'underline' } }}
            {...props}>
            {props.children}
        </Text>
    )
}

export const Cta = (props) => {
    return (
        <Text
            as="a"
            display="block"
            sx={{ cursor: 'pointer' }}
            letterSpacing="1px"
            lineHeight={1.25}
            fontWeight="regular"
            fontFamily="bold"
            fontSize={0}
            py={[10, 10, 0]}
            {...props}>
            {props.children}
            {props.arrow && (
                <Box display="inline-block" width={16} ml={1}>
                    <ArrowIcon />
                </Box>
            )}
        </Text>
    )
}
