import React from 'react'
import { inject, observer } from 'mobx-react'
import { Box } from 'rebass/styled-components'
import PropTypes from 'prop-types'
import t from 'typy'
import CountUp from 'react-countup'
import { Headline, Body, Cta } from '../typography'
import { logEvent } from '../services/AnalyticsService'

export const ColumnCta = inject('modal')(
    observer(({ title, body, ImageComponent, cta, button, source, modal, analytics, animate, ...props }) => {
        const _handleModal = (e) => {
            e.preventDefault()
            modal.showModal(t(cta).safeNumber)

            if (analytics) {
                logEvent(analytics.category, analytics.action, analytics.label)
            }
        }

        return (
            <Box
                flexBasis="100%"
                px={[0, 0, 4, 5]}
                mb={[4, 4, 0, 0]}
                sx={{ svg: { width: 'auto' }, position: 'relative', overflow: 'hidden' }}
                {...props}>
                {ImageComponent}
                {title && animate ? (
                    <CountUp
                        start={0}
                        end={parseFloat(title.replace(/,/g, ''))}
                        delay={0}
                        duration={2}
                        useEasing={false}
                        separator=","
                        prefix={title && title.charAt(0) === '+' ? title.charAt(0) : null}
                        suffix={title && title && title.slice(-1) === '%' ? title.slice(-1) : null}
                        onEnd={() => {
                            const headlines = document.querySelectorAll('.headline-animate')

                            headlines.forEach((headline) => {
                                headline.classList.add('zoom')
                            })
                        }}>
                        {({ countUpRef }) => (
                            <Headline
                                className="headline-animate"
                                sx={{
                                    transform: 'scale(1)',
                                    transition: 'transform 0.3s ease',
                                    transformOrigin: 'bottom left',
                                    '&.zoom': {
                                        transform: 'scale(1.1)',
                                    },
                                }}
                                mb={2}
                                mt={[2, 2, 0]}
                                ref={countUpRef}
                            />
                        )}
                    </CountUp>
                ) : (
                    title && (
                        <Headline mt={[2, 2, 0]} mb={2} data-ani-child data-ani-preset="fade" data-ani-child-order="1">
                            {title}
                        </Headline>
                    )
                )}
                {body && (
                    <Body
                        data-ani-child
                        data-ani-preset="fade-up"
                        data-ani-child-order="2"
                        ani-move-distance="30"
                        mb={[1, 1, 2]}
                        dangerouslySetInnerHTML={{ __html: body }}
                    />
                )}
                {source && (
                    <Body
                        data-ani-child
                        data-ani-preset="fade-up"
                        ani-move-distance="30"
                        data-ani-child-order="3"
                        fontSize={12}>
                        Source: {source}
                    </Body>
                )}
                {t(cta).isDefined && (
                    <Cta
                        data-ani-child
                        data-ani-preset="fade-up"
                        ani-move-distance="30"
                        data-ani-child-order="4"
                        maxWidth="85%"
                        display="inline-block"
                        arrow
                        tabIndex={0}
                        href={t(cta).isString ? cta : null}
                        target={t(cta).isString ? '_blank' : null}
                        onClick={(e) =>
                            t(cta).isNumber
                                ? _handleModal(e)
                                : analytics
                                ? logEvent(analytics.category, analytics.action, analytics.label)
                                : null
                        }
                        mt={[1, 1, 2]}>
                        {button}
                    </Cta>
                )}
            </Box>
        )
    })
)

ColumnCta.propTypes = {
    title: PropTypes.string,
    ImageComponent: PropTypes.any,
    body: PropTypes.string,
    cta: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    source: PropTypes.string,
    button: PropTypes.string,
    analytics: PropTypes.shape({
        category: PropTypes.string,
        action: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }),
}
