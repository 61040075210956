import React from 'react'
import PropTypes from 'prop-types'
import { Body } from '../typography'

export const SkipNav = (props) => (
    <Body
        $as="a"
        href="#ada-content-begin"
        sx={{
            border: 0,
            clip: 'rect(1px, 1px, 1px, 1px)',
            clipPath: 'inset(50%)',
            height: '1px',
            margin: '-1px',
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            width: '1px',
            wordWrap: 'normal',

            '&:focus': {
                bg: 'white',
                borderRadius: '3px',
                boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.6)',
                clip: 'auto',
                clipPath: 'none',
                color: 'black.1',
                display: 'block',
                height: 'auto',
                left: '5px',
                lineHeight: 'normal',
                padding: '1em',
                textDecoration: 'none',
                top: '5px',
                width: 'auto',
                zIndex: 100000,
            },
        }}>
        {props.children}
    </Body>
)

SkipNav.propTypes = {
    children: PropTypes.node,
}
