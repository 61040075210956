import React, { useEffect } from 'react'
import { Box, Flex, Image } from 'rebass/styled-components'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import t from 'typy'
import { Ani } from 'ani-friend'
import { Headline, Body, Cta } from '../typography'
import { Inner } from './inner'
import { FacebookIcon } from '../icons/facebook'
import { InstagramIcon } from '../icons/instagram'
import { TwitterIcon } from '../icons/twitter'
import { logEvent } from '../services/AnalyticsService'

const ani = new Ani()

const BackgroundImage = styled(Image)`
    object-position: center center;
    width: 100%;
    z-index: 1;
`

export const ImageCta = ({
    title,
    body,
    background,
    color,
    link,
    button,
    position,
    social,
    fontSize = [3, 3, 4, 5],
    fontWeight = 'regular',
    fontFamily = 'book',
    analytics,
    ImageComponent,
    ...props
}) => {
    useEffect(() => {
        ani.update()
    }, [])

    return (
        <Box py={[4, 4, 0]} {...props}>
            {title && (
                <Headline
                    data-ani-child
                    data-ani-preset="fade"
                    data-ani-child-order="1"
                    display={['block', 'block', 'none']}
                    color="black"
                    dangerouslySetInnerHTML={{ __html: title }}
                    mb={3}
                />
            )}
            {body && (
                <Body
                    data-ani-child
                    data-ani-preset="fade-up"
                    ani-move-distance="30"
                    data-ani-child-order="2"
                    color="gray"
                    display={['block', 'block', 'none']}
                    mb={3}>
                    {body}
                </Body>
            )}
            {link && (
                <Cta
                    data-ani-child
                    data-ani-preset="fade-up"
                    ani-move-distance="30"
                    data-ani-child-order="3"
                    display={['block', 'block', 'none']}
                    color="black"
                    arrow
                    href={link}
                    target="_blank"
                    my={[3, 3, 0, 0]}>
                    {button || 'Learn More'}
                </Cta>
            )}
            {social && (
                <Flex mb={[3, 3, 4, 4]} display={['flex', 'flex', 'none']} flexDirection="column">
                    <Box data-ani-child data-ani-preset="fade" data-ani-child-order="4" mr={2} mb={2}>
                        <Flex
                            as="a"
                            alignItems="center"
                            href="https://www.instagram.com/recordingacademy/"
                            target="_blank"
                            onClick={() => logEvent(analytics.category, 'join us', 'ig')}>
                            <Box width={24} color="black">
                                <InstagramIcon />
                            </Box>
                            <Cta as="span" ml={2}>
                                @RecordingAcademy
                            </Cta>
                        </Flex>
                    </Box>
                    <Box data-ani-child data-ani-preset="fade" data-ani-child-order="5" mr={2} mb={2}>
                        <Flex
                            as="a"
                            alignItems="center"
                            href="https://www.twitter.com/RecordingAcad/"
                            target="_blank"
                            onClick={() => logEvent(analytics.category, 'join us', 'twitter')}>
                            <Box width={24} color="black">
                                <TwitterIcon />
                            </Box>
                            <Cta as="span" ml={2}>
                                @RecordingAcad
                            </Cta>
                        </Flex>
                    </Box>
                    <Box data-ani-child data-ani-preset="fade" data-ani-child-order="6" mr={2} mb={2}>
                        <Flex
                            as="a"
                            alignItems="center"
                            href="https://www.facebook.com/RecordingAcademy/"
                            target="_blank"
                            onClick={() => logEvent(analytics.category, 'join us', 'fb')}>
                            <Box width={20} color="black">
                                <FacebookIcon />
                            </Box>
                            <Cta as="span" ml={2}>
                                @RecordingAcademy
                            </Cta>
                        </Flex>
                    </Box>
                </Flex>
            )}
            <Flex
                sx={{ position: 'relative' }}
                display={['block', 'block', 'flex']}
                flexWrap={['wrap', 'wrap', 'nowrap']}
                {...props}>
                {background && (
                    <BackgroundImage
                        data-ani-child
                        data-ani-preset="fade"
                        data-ani-child-order="1"
                        data-load-src={background}
                        sx={{
                            objectFit: ['unset', 'unset', 'cover'],
                            position: ['relative', 'relative', 'absolute'],
                            top: ['auto', 'auto', 0],
                            left: ['auto', 'auto', 0],
                            height: ['auto', 'auto', '100%'],
                        }}
                    />
                )}
                <Flex
                    width={[1, 1, t(ImageComponent).isDefined ? 6 / 12 : 1]}
                    py={[0, 0, 5, 5]}
                    flexWrap="wrap"
                    flexDirection="column"
                    justifyContent={position || 'center'}
                    color={color || 'black'}
                    sx={{ position: 'relative', zIndex: 2 }}>
                    <Inner>
                        {title && (
                            <Headline
                                data-ani-child
                                data-ani-preset="fade"
                                data-ani-child-order="2"
                                display={['none', 'none', 'block']}
                                maxWidth={`${t(ImageComponent).isDefined || !social ? '75%' : '45%'}`}
                                fontSize={fontSize}
                                fontWeight={fontWeight}
                                fontFamily={fontFamily}
                                color={color || 'black'}
                                dangerouslySetInnerHTML={{ __html: title }}
                                mb={body && [3, 3, 4, 4]}
                            />
                        )}
                        {body && (
                            <Body
                                data-ani-child
                                data-ani-preset="fade-up"
                                ani-move-distance="30"
                                data-ani-child-order="3"
                                color={color || 'gray'}
                                display={['none', 'none', 'block']}
                                maxWidth={['100%', '100%', t(ImageComponent).isDefined ? '75%' : '35%']}>
                                {body}
                            </Body>
                        )}
                        {link && (
                            <Cta
                                data-ani-child
                                data-ani-preset="fade"
                                data-ani-child-order="4"
                                display={['none', 'none', 'inline-block']}
                                color={color || 'black'}
                                arrow
                                href={link}
                                target="_blank"
                                mt={[3, 3, 4, 4]}>
                                {button || 'Learn More'}
                            </Cta>
                        )}
                        {social && (
                            <Flex mt={[3, 3, 4, 4]} display={['none', 'none', 'flex']} alignItems="center">
                                <Box data-ani-child data-ani-preset="fade" data-ani-child-order="4" mr={2}>
                                    <Flex
                                        as="a"
                                        alignItems="center"
                                        href="https://www.instagram.com/recordingacademy/"
                                        target="_blank"
                                        onClick={() => logEvent(analytics.category, 'join us', 'ig')}>
                                        <Box width={24}>
                                            <InstagramIcon />
                                        </Box>
                                        <Cta as="span" ml={1}>
                                            @RecordingAcademy
                                        </Cta>
                                    </Flex>
                                </Box>
                                <Box data-ani-child data-ani-preset="fade" data-ani-child-order="5" mr={2}>
                                    <Flex
                                        as="a"
                                        alignItems="center"
                                        href="https://www.twitter.com/RecordingAcad/"
                                        target="_blank"
                                        onClick={() => logEvent(analytics.category, 'join us', 'twitter')}>
                                        <Box width={24}>
                                            <TwitterIcon />
                                        </Box>
                                        <Cta as="span" ml={1}>
                                            @RecordingAcad
                                        </Cta>
                                    </Flex>
                                </Box>
                                <Box data-ani-child data-ani-preset="fade" data-ani-child-order="6" mr={2}>
                                    <Flex
                                        as="a"
                                        alignItems="center"
                                        href="https://www.facebook.com/RecordingAcademy/"
                                        target="_blank"
                                        onClick={() => logEvent(analytics.category, 'join us', 'fb')}>
                                        <Box width={20}>
                                            <FacebookIcon />
                                        </Box>
                                        <Cta as="span" ml={1}>
                                            @RecordingAcademy
                                        </Cta>
                                    </Flex>
                                </Box>
                            </Flex>
                        )}
                    </Inner>
                </Flex>
                {t(ImageComponent).isDefined && (
                    <Flex
                        width={[1, 1, 6 / 12]}
                        flexWrap="wrap"
                        alignItems="flex-end"
                        sx={{ position: 'relative', overflow: 'visible' }}>
                        {ImageComponent}
                    </Flex>
                )}
            </Flex>
        </Box>
    )
}

ImageCta.propTypes = {
    ImageComponent: PropTypes.any,
    title: PropTypes.string,
    background: PropTypes.string,
    position: PropTypes.oneOf(['flex-start', 'center', 'space-between', 'space-evenly', 'flex-end']),
    body: PropTypes.string,
    color: PropTypes.string,
    link: PropTypes.string,
    fontSize: PropTypes.any,
    fontWeight: PropTypes.any,
    fontFamily: PropTypes.any,
    button: PropTypes.string,
    social: PropTypes.bool,
    analytics: PropTypes.shape({
        category: PropTypes.string,
        action: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }),
}
