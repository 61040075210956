import React from 'react'

export const MicIcon = () => (
    <svg width="102" height="139" viewBox="0 0 102 139" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M51 87.5876C63.035 87.5876 72.6775 77.8726 72.6775 65.8376L72.75 22.3376C72.75 10.3026 63.035 0.587646 51 0.587646C38.965 0.587646 29.25 10.3026 29.25 22.3376V65.8376C29.25 77.8726 38.965 87.5876 51 87.5876ZM89.425 65.8376C89.425 87.5876 71.01 102.813 51 102.813C30.99 102.813 12.575 87.5876 12.575 65.8376H0.25C0.25 90.5602 19.97 111.005 43.75 114.558V138.338H58.25V114.558C82.03 111.078 101.75 90.6326 101.75 65.8376H89.425Z"
            fill="#DEDEE1"
        />
    </svg>
)
