import React from 'react'

export const InstagramIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9365 7.06873C8.97358 7.06873 6.51855 9.48142 6.51855 12.4867C6.51855 15.492 8.93125 17.9047 11.9365 17.9047C14.9418 17.9047 17.3545 15.4497 17.3545 12.4867C17.3545 9.52375 14.8995 7.06873 11.9365 7.06873ZM11.9365 15.9576C10.0318 15.9576 8.46564 14.3915 8.46564 12.4867C8.46564 10.582 10.0318 9.01582 11.9365 9.01582C13.8413 9.01582 15.4074 10.582 15.4074 12.4867C15.4074 14.3915 13.8413 15.9576 11.9365 15.9576Z"
            fill="currentColor"
        />
        <path
            d="M17.5661 8.16938C18.2441 8.16938 18.7936 7.61981 18.7936 6.94187C18.7936 6.26393 18.2441 5.71436 17.5661 5.71436C16.8882 5.71436 16.3386 6.26393 16.3386 6.94187C16.3386 7.61981 16.8882 8.16938 17.5661 8.16938Z"
            fill="currentColor"
        />
        <path
            d="M20.7408 3.76719C19.6403 2.62433 18.0741 2.03174 16.2964 2.03174H7.57678C3.89424 2.03174 1.43921 4.48676 1.43921 8.1693V16.8466C1.43921 18.6667 2.0318 20.2328 3.21699 21.3757C4.35984 22.4762 5.88365 23.0264 7.6191 23.0264H16.254C18.0741 23.0264 19.5979 22.4339 20.6985 21.3757C21.8413 20.2751 22.4339 18.709 22.4339 16.8889V8.1693C22.4339 6.39153 21.8413 4.86772 20.7408 3.76719ZM20.5715 16.8889C20.5715 18.2011 20.1059 19.2593 19.344 19.9788C18.5821 20.6984 17.5239 21.0794 16.254 21.0794H7.6191C6.34926 21.0794 5.29106 20.6984 4.52916 19.9788C3.76725 19.2169 3.3863 18.1587 3.3863 16.8466V8.1693C3.3863 6.89946 3.76725 5.84126 4.52916 5.07936C5.24873 4.35978 6.34926 3.97883 7.6191 3.97883H16.3387C17.6085 3.97883 18.6667 4.35978 19.4286 5.12169C20.1482 5.88359 20.5715 6.94179 20.5715 8.1693V16.8889Z"
            fill="currentColor"
        />
    </svg>
)
