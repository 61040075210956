import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Image } from 'rebass/styled-components'
import { Waypoint } from 'react-waypoint'
import { Body } from '../typography'
import { ImageCta } from '../components/image-cta'
import { Container } from '../components/container'
import { Inner } from '../components/inner'
import { ColumnCta } from '../components/column-cta'
import { Grid } from '../components/grid'
import { MicIcon } from '../icons/mic'
import { WalletIcon } from '../icons/wallet'
import { AlarmIcon } from '../icons/alarm'
import { Feed } from '../components/feed'
import { logEvent } from '../services/AnalyticsService'

const IntroImage = styled(Image)`
    object-position: center center;
    width: 100%;
    z-index: 1;
`

// Simple flags to check if section has already been in viewport
// So we don't trigger things multiple times
const sectionsVisible = {
    one: false,
    two: false,
    three: false,
    four: false,
}

export const Home = () => {
    const [animate, setAnimate] = useState(false)

    return (
        <Container>
            <ImageCta
                data-ani="basic-appear"
                title="March for Music Education"
                body="Music programs change lives! Join us to support music education and celebrate educators throughout the month of March."
                height={['auto', 'auto', 'calc(75vh - 96px)', 'calc(100vh - 96px)']}
                maxHeight={['auto', 'auto', '800px']}
                ImageComponent={
                    <>
                        <IntroImage
                            sx={{
                                position: ['relative', 'relative', 'absolute'],
                                objectFit: ['unset', 'unset', 'contain'],
                                pointerEvents: 'none',
                                userSelect: 'none',
                                top: ['auto', 'auto', 0],
                                left: ['auto', 'auto', 0],
                                height: ['auto', 'auto', '100%'],
                            }}
                            data-ani-child
                            data-load-src="/assets/images/her.jpg"
                            alt="H.E.R."
                        />
                        <Body
                            sx={{ position: 'relative', zIndex: 2, mb: [0, 0, '5%'], mr: [0, 0, '30%'] }}
                            fontStyle="italic"
                            textAlign={['left', 'left', 'center']}
                            fontSize={12}
                            width={1}>
                            &ldquo;Dream your Dream and Then get up and Create Your Reality...&rdquo; — H.E.R.
                        </Body>
                    </>
                }
            />
            <Waypoint
                onEnter={() => {
                    if (!sectionsVisible.one) {
                        sectionsVisible.one = true
                        logEvent(undefined, 'mission statement', '')
                    }
                }}>
                <div>
                    <ImageCta
                        data-ani="basic-appear"
                        title={`Participating in music programs can be a transformative experience for youth.<br><br>GRAMMY Music Education Initiatives have impacted more than 300,000 students through a variety of innovative educational programs, with the collaboration, support, and expertise of Recording Academy members.`}
                        fontSize={[1, 1, 2, 3]}
                        fontWeight="medium"
                        fontFamily="medium"
                        height={['auto', 'auto', 440, 640]}
                        color="white"
                        position="flex-end"
                        background="/assets/images/music-group.jpg"
                    />
                </div>
            </Waypoint>
            <Waypoint
                onEnter={() => {
                    if (!sectionsVisible.two) {
                        sectionsVisible.two = true
                        setAnimate(true)
                        logEvent(undefined, 'by the numbers', '')
                    }
                }}>
                <div>
                    <Inner mt={[4, 4, 5, 5]} mb={[4, 4, 5, 6]}>
                        <Grid title="Music Education by the Numbers">
                            <ColumnCta
                                title="4,000,000"
                                body="Public school students in the U.S. have NO access to music education. Together, we can help change that!"
                                cta={0}
                                source="ELMA Philanthropy services"
                                button="Share"
                                analytics={{ action: 'share', label: '4,000,000' }}
                                data-ani="basic-appear"
                                animate={animate}
                            />
                            <ColumnCta
                                title="+20%"
                                body="Increased graduation rate for schools with music programs compared to schools without."
                                cta={1}
                                source={`NAfME "Music Makes the Grade."`}
                                button="Share"
                                analytics={{ action: 'share', label: '+20% grad rate' }}
                                data-ani="basic-appear"
                                animate={animate}
                            />
                            <ColumnCta
                                title="+40%"
                                body="Improvement in academics for lower socioeconomic students grade 8-12 who took music lessons."
                                cta={2}
                                source="Gardiner, Fox, Jeffrey and Knowles"
                                button="Share"
                                analytics={{ action: 'share', label: '+40% academics' }}
                                data-ani="basic-appear"
                                animate={animate}
                            />
                        </Grid>
                    </Inner>
                </div>
            </Waypoint>
            <Waypoint
                onEnter={() => {
                    if (!sectionsVisible.three) {
                        sectionsVisible.three = true
                        logEvent(undefined, 'make a difference', '')
                    }
                }}>
                <div>
                    <Inner my={[4, 4, 5, 6]}>
                        <Grid title="Ways to Make a Difference">
                            <ColumnCta
                                title="Advocate"
                                body="Urge Congress to support music education for all students through federal legislation."
                                ImageComponent={
                                    <Box
                                        data-ani-child
                                        data-ani-preset="fade"
                                        data-ani-child-order="1"
                                        width={['30%', '30%', 1]}
                                        sx={{ svg: { width: '100%' } }}
                                        height={150}
                                        mb={3}>
                                        <MicIcon />
                                    </Box>
                                }
                                cta={3}
                                button="Take Action"
                                analytics={{ action: 'take action', label: 'advocate' }}
                                data-ani="basic-appear"
                            />
                            <ColumnCta
                                title="Volunteer"
                                ImageComponent={
                                    <Box
                                        data-ani-child
                                        data-ani-preset="fade"
                                        data-ani-child-order="2"
                                        width={['30%', '30%', 1]}
                                        sx={{ svg: { width: '100%' } }}
                                        height={150}
                                        mb={3}>
                                        <AlarmIcon />
                                    </Box>
                                }
                                body="Explore ways to support local schools, career days, or after school programs in your chapter."
                                cta={4}
                                button="Learn More"
                                analytics={{ action: 'learn more', label: 'volunteer' }}
                                data-ani="basic-appear"
                            />
                            <ColumnCta
                                title="Donate"
                                ImageComponent={
                                    <Box
                                        data-ani-child
                                        data-ani-preset="fade"
                                        data-ani-child-order="3"
                                        width={['30%', '30%', 1]}
                                        sx={{ svg: { width: '100%' } }}
                                        height={150}
                                        mb={3}>
                                        <WalletIcon />
                                    </Box>
                                }
                                body="Donate to the GRAMMY Education programs including GRAMMY Camp."
                                cta="https://29105.blackbaudhosting.com/29105/Annual-Fund"
                                button="Support"
                                analytics={{ action: 'donate', label: 'grammy museum' }}
                                data-ani="basic-appear"
                            />
                        </Grid>
                    </Inner>
                </div>
            </Waypoint>
            <Waypoint
                onEnter={() => {
                    if (!sectionsVisible.four) {
                        sectionsVisible.four = true
                        logEvent(undefined, 'social tribute', '')
                    }
                }}>
                <div data-ani="basic-appear">
                    <Feed title="Social Tributes" data-ani-child data-ani-preset="fade" data-ani-child-order="1" />
                </div>
            </Waypoint>
            <ImageCta
                data-ani="basic-appear"
                title="Follow #MarchForMusicEd"
                body="And share your story honoring the unsung heroes who helped change your life."
                height={['auto', 'auto', 440, 640]}
                position="flex-end"
                fontSize={[2, 2, 3, 4]}
                mb={[3, 3, 4]}
                color="white"
                background="/assets/images/grammy-camp.jpg"
                social
            />
        </Container>
    )
}
