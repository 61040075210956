export const theme = {
    breakpoints: ['320px', '768px', '1024px', '1440px'],
    fonts: {
        light: 'galaxie-polaris-light, Helvetica, Arial, sans-serif',
        book: 'galaxie-polaris-book, Helvetica, Arial, sans-serif',
        medium: 'galaxie-polaris-medium, Helvetica, Arial, sans-serif',
        bold: 'galaxie-polaris-bold, Helvetica, Arial, sans-serif',
        heavy: 'galaxie-polaris-heavy, Helvetica, Arial, sans-serif',
    },
    fontWeights: {
        bold: 700,
        medium: 500,
        regular: 400,
    },
    fontSizes: [12, 16, 20, 25, 30, 36],
    space: [0, 8, 16, 24, 32, 64, 128],
    sizes: [1376],
    colors: {
        black: '#000000',
        white: '#FFFFFF',
        gray: '#90909A',
        lightGray: '#DEDEE1',
        red: '#EB3337',
        teal: '#4098D3',
    },
    buttons: {
        primary: {
            color: 'white',
            bg: 'black',
            alignItems: 'center',
            display: 'inline-flex',
            py: 10,
            borderRadius: 5,
            fontSize: 12,
            fontFamily: 'galaxie-polaris-bold, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
    },
    forms: {
        input: {
            bg: '#DEDEE1',
            borderRadius: 5,
        },
    },
}
