import ReactGA from 'react-ga'
import config from '../config'

export const initGA = () => {
    if (config.isProd) {
        ReactGA.initialize(config.ga, {
            titleCase: false,
        })
    }
}

export const logPageView = (page) => {
    if (config.isProd) {
        const slug = page || window.location.pathname
        ReactGA.pageview(slug)
    }
}

export const logEvent = (category = 'march music ed', action = '', label = '') => {
    if (category && action && config.isProd) {
        ReactGA.event({ category, action, label })
    }
}

export const logException = (description = '', fatal = false) => {
    if (description && config.isProd) {
        ReactGA.exception({ description, fatal })
    }
}
