import React from 'react'
import styled from 'styled-components'
import { Box, Flex, Text, Image } from 'rebass/styled-components'
import { Container } from './container'

const FooterBox = styled(Box)`
    background-image: linear-gradient(135deg, #7544ce 25%, #1192fd 75%);
`

const FooterWrapper = styled(Flex)`
    justify-content: space-between;
    max-width: 1260px;
    margin: 0 auto;
    flex-wrap: wrap;
    width: 100%;

    &.desktop {
        @media only screen and (max-width: 320px) {
            display: none;
        }
    }
`

const FooterHeader = styled(Text).attrs(() => ({
    as: 'a',
}))`
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.bold};
    color: white;
    width: 100%;
    display: block;
`

const FooterList = styled(Box).attrs(() => ({
    as: 'ul',
    mt: 40,
}))`
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.medium};
    color: white;
    list-style: none;
`

const FooterItem = styled(Box).attrs(() => ({
    as: 'li',
    mb: 20,
}))`
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.medium};
    color: white;
    list-style: none;
`

const FooterLink = styled(Text).attrs((props) => ({
    as: 'a',
}))`
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.medium};
    color: white;

    @media only screen and (max-width: 375px) {
        font-size: 10px;
    }
`

const FooterBody = styled(Text)`
    font-size: 12px;
    color: white;
    line-height: 17px;
    font-weight: 500;
`

export const Footer = () => (
    <Container px={0}>
        <FooterBox pt={30} pb={12}>
            {/* Desktop */}
            <FooterWrapper className="desktop" display={['none', 'none', 'flex']}>
                <Flex width={1} mx="auto" flexWrap="nowrap" justifyContent="space-between">
                    <Box px={20}>
                        <FooterHeader href="https://www.grammy.com/recording-academy">Recording Academy</FooterHeader>
                        <FooterList display={['none', 'none', 'block']}>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/recording-academy/membership/recording-academy/about/chapters/recording-academy">
                                    About
                                </FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/recording-academy/membership/recording-academy/about/chapters/recording-academy/governance">
                                    Governance
                                </FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/recording-academy/press-room">
                                    Press Room
                                </FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.org/recording-academy/jobs">Jobs</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/events">Events</FooterLink>
                            </FooterItem>
                        </FooterList>
                    </Box>
                    <Box px={20}>
                        <FooterHeader href="https://www.grammy.com/grammys">GRAMMYs</FooterHeader>
                        <FooterList display={['none', 'none', 'block']}>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/grammys/awards">Awards</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/grammys/news">News</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/grammys/videos">Videos</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/events?field_pillar_tid=2">Events</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://grammystore.com/">Store</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/recording-academy/faq">FAQ</FooterLink>
                            </FooterItem>
                        </FooterList>
                    </Box>
                    <Box px={20}>
                        <FooterHeader href="https://www.latingrammy.com/">Latin GRAMMYs</FooterHeader>
                        <FooterList display={['none', 'none', 'block']}>
                            <FooterItem>
                                <FooterLink href="https://www.latingrammy.com/es/calendario-departamento-de-premiaci-n">
                                    Awards
                                </FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.latingrammy.com/es/news">News</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.latingrammy.com/es/photos">Photos</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.latingrammy.com/es/videos/latest">Videos</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.latingrammy.com/es/cultural-foundation">
                                    Cultural Foundation
                                </FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.latingrammy.com/es/my-membership">Members</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.latingrammy.com/es/press">Press</FooterLink>
                            </FooterItem>
                        </FooterList>
                    </Box>
                    <Box px={20}>
                        <FooterHeader href="http://www.grammymuseum.org/">GRAMMY Museum</FooterHeader>
                        <FooterList display={['none', 'none', 'block']}>
                            <FooterItem>
                                <FooterLink href="http://www.grammymuseum.org/explore">Explore</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="http://www.grammymuseum.org/exhibits">Exhibits</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="http://www.grammymuseum.org/education">Education</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="http://www.grammymuseum.org/support">Support</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="http://www.grammymuseum.org/programs">Programs</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://29105.blackbaudhosting.com/29105/Operating?">
                                    Donate
                                </FooterLink>
                            </FooterItem>
                        </FooterList>
                    </Box>
                    <Box px={20}>
                        <FooterHeader href="https://www.grammy.com/musicares">MusiCares</FooterHeader>
                        <FooterList display={['none', 'none', 'block']}>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/musicares/about">About</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/musicares/get-help">Get Help</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/musicares/donations">Give</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/musicares/news">News</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/musicares/videos">Videos</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/events?field_pillar_tid=4">Events</FooterLink>
                            </FooterItem>
                        </FooterList>
                    </Box>
                    <Box px={20}>
                        <FooterHeader href="https://www.grammy.com/advocacy">Advocacy</FooterHeader>
                        <FooterList display={['none', 'none', 'block']}>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/advocacy/about">About</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/advocacy/news">News</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/advocacy/issues-policy">Learn</FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/advocacy/act">Act</FooterLink>
                            </FooterItem>
                        </FooterList>
                    </Box>
                    <Box px={20}>
                        <FooterHeader href="https://www.grammy.com/recording-academy/membership/recording-academy/about/chapters/recording-academy">
                            Membership
                        </FooterHeader>
                        <FooterList display={['none', 'none', 'block']}>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/recording-academy/membership/recording-academy/about/chapters/recording-academy">
                                    Chapters
                                </FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/recording-academy/membership/recording-academy/about/chapters/producers-engineers-wing">
                                    Producers & Engineers
                                </FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/recording-academy/membership/recording-academy/about/chapters/grammy-university">
                                    GRAMMY U
                                </FooterLink>
                            </FooterItem>
                            <FooterItem>
                                <FooterLink href="https://www.grammy.com/recording-academy/membership/join">
                                    Join
                                </FooterLink>
                            </FooterItem>
                        </FooterList>
                    </Box>
                </Flex>
                <Flex
                    flexWrap={['wrap', 'wrap', 'nowrap']}
                    px={20}
                    mt={3}
                    justifyContent="space-between"
                    width={1}
                    alignItems="flex-end">
                    <Image
                        width={1}
                        maxWidth={158}
                        height="auto"
                        src="/assets/images/grammys-logo-white.png"
                        mr={[2, 2, 3]}
                    />
                    <Flex width={1} justifyContent="flex-end">
                        <FooterLink>© {new Date().getFullYear()} - Recording Academy. All rights reserved</FooterLink>
                        <FooterLink style={{ marginLeft: 20 }} href="https://www.grammy.com/legal">
                            Terms of Service
                        </FooterLink>
                        <FooterLink style={{ marginLeft: 20 }} href="https://www.grammy.com/privacy-policy">
                            Privacy Policy
                        </FooterLink>
                        <FooterLink style={{ marginLeft: 20 }} href="https://www.grammy.com/cookie-policy">
                            Cookie Policy
                        </FooterLink>
                        <FooterLink
                            style={{ marginLeft: 20 }}
                            href="https://www.grammy.com/digital-millennium-copyright-act-notice">
                            Copyright Notice
                        </FooterLink>
                        <FooterLink style={{ marginLeft: 20 }} href="https://www.grammy.com/contact">
                            Contact Us
                        </FooterLink>
                    </Flex>
                </Flex>
                <FooterBody px={20} mt={2}>
                    Some of the content on this site expresses viewpoints and opinions that are not those of the
                    Recording Academy. Responsibility for the accuracy of information provided in stories not written by
                    or specifically prepared for the Academy lies with the story's original source or writer. Content on
                    this site does not reflect an endorsement or recommendation of any artist or music by the Recording
                    Academy.
                </FooterBody>
            </FooterWrapper>
            {/* Mobile */}
            <FooterWrapper className="mobile" display={['flex', 'flex', 'none']}>
                <Flex px={2} flexWrap="wrap">
                    <Box>
                        <Image
                            width={1}
                            maxWidth={158}
                            display="inline-block"
                            height="auto"
                            src="/assets/images/grammys-logo-white.png"
                            mr={[2, 2, 3]}
                            mb={35}
                        />
                    </Box>
                    <Flex width={1} alignItems="flex-start" mb={35}>
                        <Flex justifyContent="center" flexWrap="wrap" width={0.5}>
                            <FooterHeader href="https://www.grammy.com/recording-academy">
                                Recording Academy
                            </FooterHeader>
                            <FooterHeader style={{ marginTop: 35 }} href="https://www.grammy.com/grammys">
                                GRAMMYs
                            </FooterHeader>
                            <FooterHeader style={{ marginTop: 35 }} href="https://www.latingrammy.com/">
                                Latin GRAMMYs
                            </FooterHeader>
                            <FooterHeader style={{ marginTop: 35 }} href="http://www.grammymuseum.org/">
                                GRAMMY Museum
                            </FooterHeader>
                        </Flex>
                        <Flex justifyContent="center" flexWrap="wrap" width={0.5}>
                            <FooterHeader href="https://www.grammy.com/musicares">MusiCares</FooterHeader>
                            <FooterHeader style={{ marginTop: 35 }} href="https://www.grammy.com/advocacy">
                                Advocacy
                            </FooterHeader>
                            <FooterHeader
                                style={{ marginTop: 35 }}
                                href="https://www.grammy.com/recording-academy/membership/recording-academy/about/chapters/recording-academy">
                                Membership
                            </FooterHeader>
                        </Flex>
                    </Flex>
                    <Flex width={1} mt={2} justifyContent="center" flexWrap="wrap">
                        <Flex mb={2} textAlign="center">
                            <FooterLink href="https://www.grammy.com/legal">Terms of Service</FooterLink>
                            <FooterLink style={{ marginLeft: 20 }} href="https://www.grammy.com/privacy-policy">
                                Privacy Policy
                            </FooterLink>
                            <FooterLink style={{ marginLeft: 20 }} href="https://www.grammy.com/cookie-policy">
                                Cookie Policy
                            </FooterLink>
                            <FooterLink
                                style={{ marginLeft: 20 }}
                                href="https://www.grammy.com/digital-millennium-copyright-act-notice">
                                Copyright Notice
                            </FooterLink>
                            <FooterLink style={{ marginLeft: 20 }} href="https://www.grammy.com/contact">
                                Contact Us
                            </FooterLink>
                        </Flex>
                        <FooterLink>© {new Date().getFullYear()} - Recording Academy. All rights reserved</FooterLink>
                    </Flex>
                    <FooterBody mt={2}>
                        Some of the content on this site expresses viewpoints and opinions that are not those of the
                        Recording Academy. Responsibility for the accuracy of information provided in stories not
                        written by or specifically prepared for the Academy lies with the story's original source or
                        writer. Content on this site does not reflect an endorsement or recommendation of any artist or
                        music by the Recording Academy.
                    </FooterBody>
                </Flex>
            </FooterWrapper>
        </FooterBox>
    </Container>
)
