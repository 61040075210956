import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'rebass/styled-components'
import { Headline } from '../typography'

export const Grid = ({ children, title, ...props }) => (
    <>
        {title && <Headline mb={[4, 4, 5, 5]}>{title}</Headline>}
        <Flex
            flexWrap={['wrap', 'wrap', 'nowrap']}
            justifyContent="space-between"
            sx={{
                '> *:first-child': {
                    paddingLeft: '0 !important',
                },
                '> *:last-child': {
                    paddingRight: '0 !important',
                },
            }}
            {...props}>
            {children}
        </Flex>
    </>
)

Grid.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
}
