import React from 'react'

export const CloseIcon = () => (
    <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Close Icon</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="close" transform="translate(-11.000000, -12.000000)" fill="#FFFFFF" fillRule="nonzero">
                <polygon
                    id="Combined-Shape"
                    transform="translate(24.825473, 25.223288) rotate(45.000000) translate(-24.825473, -25.223288) "
                    points="25.906208 7.9315214 25.905706 24.1425214 42.11724 24.1425526 42.11724 26.3040234 25.905706 26.3035214 25.906208 42.5150546 23.744738 42.5150546 23.744706 26.3035214 7.533706 26.3040234 7.533706 24.1425526 23.744706 24.1425214 23.744738 7.9315214"
                />
            </g>
        </g>
    </svg>
)
