import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Provider as MobXProvider } from 'mobx-react'
import { Header } from './components/header'
import { Footer } from './components/footer'
import { Home } from './pages/Home'
import Base from './components/base'
import { initGA, logPageView } from './services/AnalyticsService'
import config from './config'

// Stores
import ModalStore from './stores/ModalStore'

function App() {
    const _determineQuery = () => {
        const { search } = window.location
        const params = new URLSearchParams(search)
        const query = params.get('share')
        let param = 'share'

        switch (query) {
            case 'fab1':
                param = query
                break
            case 'fab2':
                param = query
                break
            case 'fab3':
                param = query
                break
            case 'twt1':
                param = query
                break
            case 'twt2':
                param = query
                break
            case 'twt3':
                param = query
                break
            default:
                param = 'share'
                break
        }

        return `${config.url}/assets/images/social/${param}.jpg`
    }

    useEffect(() => {
        // Setup GA if production environment
        initGA()
        logPageView()

        // Hack to remove data-react-helmet true attributes on SEO
        setTimeout(() => {
            const metas = document.querySelectorAll('[data-react-helmet]')
            metas.forEach((meta) => {
                meta.removeAttribute('data-react-helmet')
            })
        }, 0)
    }, [])

    return (
        <MobXProvider modal={ModalStore}>
            <>
                <Helmet>
                    <meta name="og:image" content={_determineQuery()} />
                    <meta name="twitter:image" content={_determineQuery()} />
                </Helmet>
                <Base>
                    <Header />
                    <Home />
                    <Footer />
                </Base>
            </>
        </MobXProvider>
    )
}

export default App
