import React from 'react'
import { observable, action, computed, decorate } from 'mobx'
import { Modal } from '../components/modal'

class ModalStore {
    modal = false

    modalIndex = 0

    // Show modal
    showModal(index) {
        this.modalIndex = index
        this.modal = true
    }

    // hide modal
    hideModal() {
        this.modal = false
    }

    get initModal() {
        return <Modal open={this.modal} index={this.modalIndex} />
    }
}

decorate(ModalStore, {
    modal: observable,
    modalIndex: observable,
    initModal: computed,
    showModal: action,
    hideModal: action,
})

export default new ModalStore()
