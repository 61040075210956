import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { Box, Flex, Button } from 'rebass/styled-components'
import { Input, Label, Checkbox } from '@rebass/forms/styled-components'
import PropTypes from 'prop-types'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Headline, Body, Cta } from '../typography'
import { CloseModalIcon } from '../icons/close-modal'
import { DownloadIcon } from '../icons/download'
import { CopyIcon } from '../icons/copy'
import { theme } from '../theme'
import { logEvent } from '../services/AnalyticsService'

const ModalOverlay = styled(Box)`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    pointer-events: ${({ active }) => (!active ? 'none' : 'auto')};
    opacity: 0;
    transition: opacity 0.5s 0.1s;
    ${({ active }) => active && `opacity: 1;`}
`

const ModalInner = styled(Box)`
    background: white;
    max-width: 910px;
    width: 100%;
    position: relative;
    pointer-events: auto;
    z-index: 99999;
`

const ModalCloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    padding: ${(props) => props.theme.space[2]}px;
`

const ModalContainer = styled(Box)`
    overflow: auto;
    height: 75vh;
    max-height: 800px;
    width: 100%;
`

const ModalFrame = styled.iframe`
    width: 100%;
    height: 630px;
`

const ModalLabel = styled(Label).attrs(() => ({
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'galaxie-polaris-medium, Helvetica, Arial, sans-serif',
    textAlign: 'left',
    sx: {
        svg: {
            color: 'black',
        },
    },
}))``

const ModalInput = styled(Input)`
    background: ${(props) => props.theme.colors.lightGray} !important;
    border: none !important;
    border-radius: 5px;
    padding-right: 40px;
`

const VideoWrapper = styled(Box)`
    position: relative;
    width: 100%;

    video {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
        width: 100%;
        height: 100%;
    }
`

const ModalShareContent = ({ index, open }) => {
    const inputRef = useRef(null)
    const [copy] = useState('https://www.grammy.com/marchformusiceducation')
    const label = index === 0 ? '4,000,000' : index === 1 ? '+20% grad rate' : '+40% academics'

    return (
        <Box maxWidth={[300, 300, 300, 448]} mx="auto">
            <Headline id="modal-title" mb={[2, 2, 3]} textAlign="center">
                Share
            </Headline>
            <VideoWrapper id="video-wrapper" height={[300, 300, 300, 448]} mb={3}>
                {open && (
                    <video autoPlay loop muted playsInline preload="auto">
                        <source
                            src={`/assets/images/social/RecordingAcademy_MarchforMusicEducation_AwarenessStat${index +
                                1}.mp4`}
                            type="video/mp4"
                        />
                        <track kind="subtitles" srcLang="en" label="English" />
                    </video>
                )}
            </VideoWrapper>
            <Box mb={[2, 2, 3]}>
                <Cta mb={2}>Share:</Cta>
                <Flex alignItems="center">
                    <Box width={48} mr={1}>
                        <FacebookShareButton
                            url={`${window.location.href.split('?')[0]}?share=fab${index + 1}`}
                            hashtag="#MarchForMusicEd"
                            onClick={() => {
                                logEvent(undefined, 'fb cta', label)
                            }}>
                            <FacebookIcon
                                size={48}
                                round
                                bgStyle={{ fill: theme.colors.lightGray }}
                                iconFillColor="black"
                            />
                        </FacebookShareButton>
                    </Box>
                    <Box width={48} mr={1}>
                        <TwitterShareButton
                            url={`${window.location.href.split('?')[0]}?share=twt${index + 1}`}
                            hashtags={['MarchForMusicEd']}
                            onClick={() => {
                                logEvent(undefined, 'twitter cta', label)
                            }}>
                            <TwitterIcon
                                size={48}
                                round
                                bgStyle={{ fill: theme.colors.lightGray }}
                                iconFillColor="black"
                            />
                        </TwitterShareButton>
                    </Box>
                    <Button
                        as="a"
                        title="#MarchForMusicEd"
                        href={`/assets/images/social/RecordingAcademy_MarchforMusicEducation_AwarenessStat${index +
                            1}.mp4`}
                        download={`RecordingAcademy_MarchforMusicEducation_AwarenessStat${index + 1}.mp4`}
                        onClick={() => {
                            logEvent(undefined, 'ig download', label)
                        }}>
                        Download Image for Instagram{' '}
                        <Box width={24} display="inline-block" ml={[1, 1, 2]}>
                            <DownloadIcon />
                        </Box>
                    </Button>
                </Flex>
            </Box>
            <Box mb={[2, 2, 3]} sx={{ position: 'relative' }}>
                <Cta mb={2}>Page Link:</Cta>
                <CopyToClipboard
                    text={copy}
                    onCopy={() => {
                        if (inputRef && inputRef.current) {
                            inputRef.current.focus()
                            inputRef.current.select()
                        }

                        logEvent(undefined, 'copy link', label)
                    }}>
                    <span
                        style={{
                            display: 'block',
                            position: 'absolute',
                            cursor: 'pointer',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </CopyToClipboard>
                <>
                    <ModalInput ref={inputRef} type="text" defaultValue={copy} />
                    <Box width={24} sx={{ position: 'absolute', bottom: 13, right: 10, pointerEvents: 'none' }}>
                        <CopyIcon />
                    </Box>
                </>
            </Box>
        </Box>
    )
}

const ModalAdvocacyContent = ({ index }) => {
    return index === 3 ? (
        <Box maxWidth={448} mx="auto">
            <Headline id="modal-title" mb={[3, 3, 4]} textAlign="center">
                Advocate
            </Headline>
            <Body textAlign="center" mb={[3, 3, 4]}>
                Let your voice be heard so Congress knows the importance of supporting music education for all youth in
                our schools.
            </Body>
            <ModalFrame title="Grammy Advocacy" src="/iframe.html" async frameBorder="0" />
        </Box>
    ) : index === 4 ? (
        <Box maxWidth={['100%', '100%', '75%']} mx="auto">
            <Headline id="modal-title" mb={[3, 3, 4]} textAlign="center">
                Volunteer
            </Headline>
            <Body textAlign="center" fontSize={14} mb={[3, 3, 4]}>
                To help spark opportunities to engage with schools or after-school programs that support local students
                and underserved youth, here are a few steps:
            </Body>
            <Box maxWidth={['100%', '100%', '60%']} mx="auto" textAlign="center">
                <Box mb={[2, 2, 3]}>
                    <ModalLabel alignItems="center">
                        <Checkbox name="dude" mr={1} />
                        1. Find a school or program that needs volunteers
                    </ModalLabel>
                </Box>
                <Box mb={[2, 2, 3]}>
                    <ModalLabel alignItems="center">
                        <Checkbox name="nothing" mr={1} />
                        2. Contact the school administration
                    </ModalLabel>
                </Box>
                <Box mb={[2, 2, 3]}>
                    <ModalLabel alignItems="center">
                        <Checkbox mr={1} />
                        3. Schedule time to help with the school
                    </ModalLabel>
                </Box>
                <Box>
                    <ModalLabel alignItems="center">
                        <Checkbox mr={1} />
                        4. Align on goals with the school or program
                    </ModalLabel>
                </Box>
                <Button
                    as="a"
                    mt={[3, 3, 4]}
                    href="/assets/downloads/checklist.pdf"
                    onClick={() => logEvent(undefined, 'checklist', 'volunteer')}
                    target="_blank">
                    Download Full Checklist
                    <Box width={24} display="inline-block" ml={[1, 1, 2]}>
                        <DownloadIcon />
                    </Box>
                </Button>
            </Box>
        </Box>
    ) : null
}

export const Modal = inject('modal')(
    observer(({ index, open, modal, ...props }) => {
        // Focus modal on open ADA
        if (open) {
            const close = document.querySelector('.close-button')
            const modalContainer = document.querySelector('.modal-container')

            // Scroll to top of modal on open
            setTimeout(() => {
                modalContainer.scrollTop = 0
            }, 0)

            if (close) {
                setTimeout(() => {
                    close.focus()
                }, 0)
            }

            // Close modal on escape
            document.onkeydown = (evt) => {
                const event = evt || window.event
                if (event.keyCode === 27) {
                    modal.hideModal()
                }
            }
        }

        const displayContent = () => {
            if (index < 3) {
                return <ModalShareContent index={index} open={open} />
            }

            if (index >= 3) {
                return <ModalAdvocacyContent index={index} open={open} />
            }
        }

        const _handleCloseModal = (e, overlay) => {
            if (overlay && e.target.classList.contains('modal-overlay')) {
                e.preventDefault()
                modal.hideModal()
            } else if (!overlay) {
                e.preventDefault()
                modal.hideModal()
            }
        }

        return (
            <>
                <ModalOverlay className="modal-overlay" onClick={(e) => _handleCloseModal(e, true)} active={open}>
                    <ModalInner
                        role="dialog"
                        id="grammy-modal"
                        aria-labelledby="modal-title"
                        aria-modal="true"
                        display={open ? 'block' : 'none'}
                        mx={[2, 2, 3]}>
                        <ModalContainer className="modal-container" p={[1, 2, 2]}>
                            <ModalCloseButton className="close-button" onClick={_handleCloseModal}>
                                <Box width={14}>
                                    <CloseModalIcon />
                                </Box>
                            </ModalCloseButton>
                            {displayContent()}
                        </ModalContainer>
                    </ModalInner>
                </ModalOverlay>
            </>
        )
    })
)

Modal.propTypes = {
    index: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
}

ModalShareContent.propTypes = {
    index: PropTypes.number.isRequired,
    open: PropTypes.bool,
}

ModalAdvocacyContent.propTypes = {
    index: PropTypes.number.isRequired,
    open: PropTypes.bool,
}
