import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Inner } from './inner'
import { Headline } from '../typography'

export const Feed = ({ title, ...props }) => {
    useEffect(() => {
        const script = document.createElement('script')

        script.src = 'https://apps.elfsight.com/p/platform.js'
        script.async = true
        script.defer = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <Inner mb={[4, 4, 5, 6]} {...props}>
            {title && <Headline dangerouslySetInnerHTML={{ __html: title }} mb={[3, 3, 4, 5]} />}
            <div className="elfsight-app-d28ff565-6624-4d22-a18e-7ba4a750e27a" />
        </Inner>
    )
}

Feed.propTypes = {
    title: PropTypes.string,
}
