import React from 'react'

export const AlarmIcon = () => (
    <svg width="146" height="147" viewBox="0 0 146 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M145.5 28.5576L112.15 0.572556L102.797 11.6651L136.147 39.6501L145.5 28.5576ZM43.1295 11.6651L33.8495 0.572556L0.499512 28.4851L9.85201 39.5776L43.1295 11.6651ZM76.6245 45.0876H65.7495V88.5876L100.187 109.25L105.625 100.333L76.6245 83.1501V45.0876ZM72.9995 16.0876C36.967 16.0876 7.74951 45.3051 7.74951 81.3376C7.74951 117.37 36.8945 146.588 72.9995 146.588C109.032 146.588 138.25 117.37 138.25 81.3376C138.25 45.3051 109.032 16.0876 72.9995 16.0876ZM72.9995 132.088C44.942 132.088 22.2495 109.395 22.2495 81.3376C22.2495 53.2801 44.942 30.5876 72.9995 30.5876C101.057 30.5876 123.75 53.2801 123.75 81.3376C123.75 109.395 101.057 132.088 72.9995 132.088Z"
            fill="#DEDEE1"
        />
    </svg>
)
