import React from 'react'
import { Box } from 'rebass/styled-components'
import PropTypes from 'prop-types'

export const Inner = ({ children, ...props }) => {
    return (
        <Box pl={props.pl || [0, 0, 4, 5]} {...props}>
            {children}
        </Box>
    )
}

Inner.propTypes = {
    children: PropTypes.any,
    pl: PropTypes.any,
}
