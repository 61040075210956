import React from 'react'
import { Box } from 'rebass/styled-components'
import PropTypes from 'prop-types'

export const Container = (props) => (
    <Box as={props.$as || 'section'} mx="auto" px={props.px || [2, 2, 2, 3]} {...props}>
        <Box maxWidth={props.maxWidth || 0} mx={props.mx || 'auto'}>
            {props.children}
        </Box>
    </Box>
)

Container.propTypes = {
    children: PropTypes.any,
    maxWidth: PropTypes.number,
    $as: PropTypes.string,
    mx: PropTypes.any,
    px: PropTypes.any,
}
