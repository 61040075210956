import React, { Component } from 'react'
import { Box, Flex, Image } from 'rebass/styled-components'
import styled from 'styled-components'
import { Container } from './container'
import { CloseIcon } from '../icons/close'
import { SkipNav } from './skip-nav'

const HeaderElement = styled.header`
    background-color: ${({ theme }) => theme.colors.white};
    height: 96px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    border-bottom: 1px solid #ffffff;

    @media (max-width: 939px) {
        height: 63px;
    }
`

const Hamburger = styled.a`
    padding: 37px 20px;
    border-right: 1px solid #e1e1e1;
    display: block;

    @media (max-width: 939px) {
        padding: 20px;
    }
`

const LogoWrap = styled.a`
    display: block;
    margin-left: 19px;
    height: auto;
    width: 189px;
`

const Logo = styled(Image)`
    height: 75px;
    max-width: 189px;
    margin-top: -1px;
    display: block;

    @media (max-width: 939px) {
        height: auto;
        max-width: 125px;
    }
`

const Nav = styled.nav`
    display: flex;
    align-items: center;
    color: black;
    font-weight: 500;
    font-size: 12px;
    margin-right: 20px;
    text-transform: uppercase;

    a {
        display: inline-block;
        white-space: nowrap;
        margin-left: 30px;
        margin-top: 4px;
        line-height: 1;
        letter-spacing: 0.01em;
        font-family: ${({ theme }) => theme.fonts.medium};

        &:last-child {
            margin-left: 34px;
            margin-top: -2px;
        }

        &.image-wrap {
            display: block;
            margin-left: 21px;
            margin-top: 0;

            &.border {
                border-left: 1px solid #e1e1e1;

                > div {
                    padding-left: 20px;
                }

                @media (max-width: 939px) {
                    border-left: none;

                    > div {
                        padding-left: 0;
                    }
                }
            }
        }
    }
`

const HamburgerMenu = styled(Box)`
    background: ${(props) =>
        props.right ? 'white' : `url('/assets/images/bg-menu-desktop.jpg') transparent no-repeat`};
    background-size: cover;
    position: fixed;
    top: 0;
    left: ${(props) => (props.right ? 'auto' : 0)};
    right: ${(props) => (props.right ? 0 : 'auto')};
    display: ${(props) => (props.show ? 'block' : 'none')};
    height: 100vh;
    width: 28vw;
    z-index: 9999;

    a:not(.close):not(.button) {
        display: block;
        color: ${(props) => (props.right ? 'black' : 'white')};
        position: relative;
        font-size: 1.17em;
        line-height: 3.7vh;
        padding: 2.7vh 0;
        font-family: ${({ theme }) => theme.fonts.book};
        text-transform: ${(props) => (props.right ? 'uppercase' : 'none')};

        &.bold {
            font-family: ${({ theme }) => theme.fonts.bold};
        }
    }

    @media (max-width: 939px) {
        height: ${(props) => (props.headerHeight ? `calc(100vh - ${props.headerHeight}px)` : 'calc(100vh - 96px)')};
        margin-top: ${(props) => (props.headerHeight ? props.headerHeight : 0)}px;
        width: 50%;

        a:not(.close):not(.button) {
            padding: 1vh 0;
            font-size: 15px;
        }
    }
`

const HamburgerMenuInner = styled(Box)`
    padding: 12vh 0vw 0vh 5vw;
    height: 100vh;

    @media (max-width: 939px) {
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 30px;
    }
`

const Close = styled.a`
    padding: 40px 24px;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 27px;
    height: 27px;

    @media (max-width: 939px) {
        display: none;
    }

    svg {
        width: 27px;
        height: 27px;
    }
`

const MobileWrap = styled(Box)`
    display: ${(props) => (props.reverse ? 'none' : 'block')};

    @media (max-width: 939px) {
        display: ${(props) => (props.reverse ? 'block' : 'none')};
    }
`

const ButtonBox = styled(Box)`
    width: 100%;
    margin-top: ${({ theme }) => theme.space[2]}px;
    text-align: center;

    a {
        display: inline-block;
        color: white;
        padding: 12px 15px 11px 15px;
        border: 1px solid white;
        font-size: 12px;
        font-family: ${({ theme }) => theme.fonts.bold};
        transition: 0.25s;
        text-transform: uppercase;

        &:first-child {
            margin-right: 5%;
        }

        &:hover {
            background-color: white;
            color: #1192fd;
        }
    }

    @media (max-width: 939px) {
        margin-top: ${({ theme }) => theme.space[1]}px;
    }
`

const Triangle = styled.div`
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: none;

    @media (max-width: 939px) {
        display: block;
    }
`

const SearchBox = styled(Box)`
    width: 42px;

    @media (max-width: 939px) {
        width: 22px;
    }
`

export class Header extends Component {
    constructor(props) {
        super(props)
        this.state = { menu: false, headerHeight: 96 }
    }

    componentDidMount() {
        this.getHeaderHeight()
        window.addEventListener('resize', this.handleResize)
        window.addEventListener('keydown', (e) => this.handleKeyDown(e))
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('keydown', (e) => this.handleKeyDown(e))
    }

    getHeaderHeight = () => {
        if (this.header) {
            const headerHeight = this.header.getBoundingClientRect().height
            this.setState({ headerHeight })
            document.querySelector('body').style.marginTop = `${headerHeight}px`
        }
    }

    handleResize = () => {
        let delay
        if (this.resizeTm) {
            clearTimeout(this.resizeTm)
            delay = 0
        }

        const fn = this.getHeaderHeight
        this.resizeTm = setTimeout(fn, delay)
    }

    handleKeyDown(e) {
        if (e) {
            switch (e.keyCode) {
                case 27:
                    this.toggleMenu(null, false) // esc key
                    break
                case 9:
                    // tab key. dont allow tabbing outside of overlay
                    if (e.target.id === 'hamburger-join-btn') {
                        document.getElementById('hambuger-menu-inner').focus()
                    }
                    break
                default:
                    break
            }
        }
    }

    toggleMenu(e, status) {
        if (e) {
            e.preventDefault()
        }

        this.setState({ menu: status })

        // focus first item in menu if opened (after timeout while waiting for close button to be availble)
        if (status) {
            setTimeout(() => {
                document.getElementById('header-hamburger-close').focus()
            }, 100)
        } else {
            document.getElementById('header-hamburger').focus()
        }
    }

    render() {
        return (
            <>
                <SkipNav>Skip To Content</SkipNav>
                <HeaderElement ref={(ref) => (this.header = ref)}>
                    <Container
                        $as="div"
                        width="100%"
                        sx={{
                            paddingLeft: '0 !important',
                            paddingRight: '0 !important',
                            borderBottom: '1px solid #e1e1e1',
                        }}
                        maxWidth={1366}>
                        <Flex justifyContent="space-between">
                            <Flex alignItems="center">
                                <Hamburger
                                    id="header-hamburger"
                                    href="#"
                                    onClick={(e) => this.toggleMenu(e, !this.state.menu)}
                                    aria-label="Hamburger Menu. Click to open menu options.">
                                    <Image
                                        width="23px"
                                        height="22px"
                                        src={`/assets/images/menu${this.state.menu ? '-close' : ''}.png`}
                                        aria-hidden="true"
                                    />
                                </Hamburger>
                                <LogoWrap href="https://www.grammy.com">
                                    <Logo
                                        src="/assets/images/grammys-logo.png"
                                        alt="Recording Academy Grammy Awards Logo. Click to go home."
                                    />
                                </LogoWrap>
                            </Flex>
                            <Nav>
                                <MobileWrap>
                                    <a href="https://www.grammy.com/grammys/awards">Awards</a>
                                    <a href="https://www.grammy.com/grammys/news">News</a>
                                    <a href="https://www.grammy.com/grammys/videos">Videos</a>
                                    <a href="https://www.grammy.com/recording-academy">Recording Academy</a>
                                    <a href="https://www.grammy.com/#node-main-22">Subscribe</a>
                                </MobileWrap>
                                <MobileWrap reverse>
                                    <a
                                        href="https://www.grammy.com/#node-main-22"
                                        className="image-wrap"
                                        aria-label="News">
                                        <Box>
                                            <Image
                                                width="22px"
                                                height="22px"
                                                src="/assets/images/share.svg"
                                                aria-hidden="true"
                                            />
                                        </Box>
                                    </a>
                                </MobileWrap>
                                <a
                                    className="image-wrap border"
                                    href="https://www.grammy.com/search"
                                    aria-label="Search">
                                    <SearchBox py={[0, '2.5px']}>
                                        <Image src="/assets/images/search.svg" aria-hidden="true" />
                                    </SearchBox>
                                </a>
                            </Nav>
                        </Flex>
                    </Container>
                </HeaderElement>
                <HamburgerMenu show={this.state.menu} headerHeight={this.state.headerHeight} aria-modal="true">
                    <HamburgerMenuInner id="hambuger-menu-inner" tabIndex={0}>
                        <Close
                            id="header-hamburger-close"
                            className="close"
                            href="/"
                            onClick={(e) => this.toggleMenu(e, false)}
                            aria-label="Close Menu Overlay">
                            <CloseIcon />
                        </Close>
                        <a href="https://www.grammy.com/recording-academy">Recording Academy</a>
                        <a className="bold" href="https://www.grammy.com/grammys">
                            GRAMMYs
                            <Triangle />
                        </a>
                        <a href="https://www.grammy.com/recording-academy/membership/recording-academy/about/chapters/recording-academy">
                            Membership
                        </a>
                        <a href="https://www.grammy.com/advocacy">Advocacy</a>
                        <a href="https://www.grammy.com/musicares">MusiCares</a>
                        <a href="http://www.grammymuseum.org/">GRAMMY Museum</a>
                        <a href="https://www.latingrammy.com/">Latin GRAMMYs</a>
                        <ButtonBox pr={[1, 1, 2]}>
                            <a className="button" href="https://www.grammy.com/user/login">
                                Log In
                            </a>
                            <a id="hamburger-join-btn" className="button" href="https://www.grammy.com/join">
                                Join
                            </a>
                        </ButtonBox>
                    </HamburgerMenuInner>
                </HamburgerMenu>
                <MobileWrap reverse>
                    <HamburgerMenu right show={this.state.menu} headerHeight={this.state.headerHeight}>
                        <HamburgerMenuInner>
                            <a href="https://www.grammy.com/grammys">Home</a>
                            <a href="https://www.grammy.com/grammys/awards">Awards</a>
                            <a href="https://www.grammy.com/grammys/news">News</a>
                            <a href="https://www.grammy.com/grammys/videos">Videos</a>
                            <a href="https://www.grammy.com/recording-academy">Recording Academy</a>
                        </HamburgerMenuInner>
                    </HamburgerMenu>
                </MobileWrap>
            </>
        )
    }
}
