import React from 'react'

export const TwitterIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.3676 6.37751C21.6009 6.71829 20.7758 6.94816 19.9101 7.05103C20.794 6.52187 21.4718 5.68367 21.7914 4.68376C20.9647 5.1744 20.0494 5.53042 19.0745 5.72262C18.2943 4.89119 17.1822 4.37134 15.9515 4.37134C13.5889 4.37134 11.6733 6.28692 11.6733 8.64996C11.6733 8.98482 11.711 9.31121 11.7843 9.6249C8.22827 9.44625 5.07528 7.74318 2.96497 5.1545C2.59667 5.78612 2.38585 6.52102 2.38585 7.30588C2.38585 8.79008 3.14108 10.0999 4.28916 10.867C3.58812 10.8445 2.92814 10.6519 2.35114 10.3314C2.35071 10.3492 2.35071 10.3674 2.35071 10.3856C2.35071 12.4583 3.82561 14.1872 5.7831 14.5809C5.42411 14.6782 5.04607 14.7307 4.65576 14.7307C4.37975 14.7307 4.11178 14.704 3.85058 14.6537C4.39541 16.3533 5.97529 17.5907 7.84727 17.625C6.38296 18.7727 4.5385 19.4568 2.53359 19.4568C2.18858 19.4568 1.8478 19.4365 1.51294 19.3967C3.40693 20.6112 5.65567 21.319 8.07163 21.319C15.9418 21.319 20.245 14.7997 20.245 9.14568C20.245 8.96026 20.2412 8.77527 20.2331 8.59154C21.0688 7.98956 21.7944 7.23561 22.3676 6.37751Z"
            fill="currentColor"
        />
    </svg>
)
