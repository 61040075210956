import React from 'react'

export const WalletIcon = () => (
    <svg width="139" height="132" viewBox="0 0 139 132" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M131.25 109.588V116.838C131.25 124.813 124.725 131.338 116.75 131.338H15.25C7.2025 131.338 0.75 124.813 0.75 116.838V15.3376C0.75 7.36265 7.2025 0.837646 15.25 0.837646H116.75C124.725 0.837646 131.25 7.36265 131.25 15.3376V22.5876H66C57.9525 22.5876 51.5 29.1126 51.5 37.0876V95.0876C51.5 103.063 57.9525 109.588 66 109.588H131.25ZM66 95.0876H138.5V37.0876H66V95.0876ZM95 76.9626C88.9825 76.9626 84.125 72.1051 84.125 66.0876C84.125 60.0701 88.9825 55.2126 95 55.2126C101.018 55.2126 105.875 60.0701 105.875 66.0876C105.875 72.1051 101.018 76.9626 95 76.9626Z"
            fill="#DEDEE1"
        />
    </svg>
)
