import React from 'react'
import { inject, observer } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../theme'

const Base = inject('modal')(
    observer(({ modal, children }) => {
        const { initModal } = modal
        return (
            <ThemeProvider theme={theme}>
                <>
                    {children}
                    {initModal}
                </>
            </ThemeProvider>
        )
    })
)

export default Base
